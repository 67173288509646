<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core'

import {
  FALLBACK_PRODUCT_IMAGE,
  PRODUCT_TILE_TRANSFORMATIONS,
  DEFAULT_TRANSFORMATIONS,
} from '~/constants/images'
import type { CatalogItem } from '~/types/catalog'
import type { ContentfulAsset } from '~/types/contentful'

import { BACK_SOON_BADGE_TEXT } from '~/constants/productBadges'

/**
 * Component for ProductCarousel widgets.
 *
 * Displays the provided catalog items in a product tile carousel and
 * according to the provided props.
 */
const props = defineProps({
  /**
   * The name of the CMS widget.
   */
  name: {
    type: String,
    default: '',
  },
  /**
   * The category of products to display in the carousel.
   */
  category: {
    type: String,
    required: true,
  },
  /**
   * Copy for widget call to action.
   */
  ctaText: {
    type: String,
    default: '',
  },
  /**
   * Destination of widget call to action.
   */
  ctaDestinationUrl: {
    type: String,
    default: '',
  },
  /**
   * The widget image to display at medium+ viewport sizes, given `isThemed` truthy.
   */
  desktopImage: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  /**
   * Alt description for widget image at medium+ viewport sizes, given
   * `isThemed` truthy. If null, the widget falls back to the description on
   * the asset.
   */
  desktopAltText: {
    type: String,
    default: null,
  },
  /**
   * Heading text for widget.
   */
  heading: {
    type: String,
    default: '',
  },
  /**
   * Whether to display a carousel in a themed layout with an accompanying
   * image/illustration.
   */
  isThemed: {
    type: Boolean,
    default: false,
  },
  /**
   * The image to display at small/mobile viewport sizes, given `isThemed` truthy.
   */
  mobileImage: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  /**
   * Alt description for widget image at small/mobile viewport sizes, given
   * `isThemed` truthy. If null, the widget falls back to the description on
   * the asset.
   */
  mobileImageAltText: {
    type: String,
    default: null,
  },
  /**
   * The subheading text for the widget.
   */
  subheading: {
    type: String,
    default: '',
  },
})

const { $events } = useNuxtApp()
const { isSmallUp, isMediumUp, isLargeUp } = useSkyBreakpoint()
// Resolution between isSmallUp and isMediumUp that doesn't match our Skylight breakpoints
const isTablet = useMediaQuery('(min-width: 800px) and (max-width: 1080px)')

const { fetchRecommendations, results: catalogItems } =
  useCatalogRecommendations()

const { hasPlacedOrder } = useCustomer()

const sectionClasses = computed(() => ({
  WidgetProductCarousel: true,
  'WidgetProductCarousel--themed': props.isThemed,
}))

const tileClasses = computed(() => ({
  WidgetProductCarousel_Tile: true,
}))

const numItemsToDisplay = computed(() => {
  if (isTablet.value) {
    return 3
  }

  if (props.isThemed && isMediumUp.value) {
    return 4
  }

  if (isLargeUp.value) {
    return 6
  }

  if (isMediumUp.value) {
    return 5
  }

  return hasPlacedOrder.value ? 2.1 : 2.5
})

const showNav = computed(() =>
  isMediumUp.value ? catalogItems.value.length > numItemsToDisplay.value : false
)

const trackCatalogClick = (item: CatalogItem) => {
  $events.emit(
    ...$events.catalogClick({
      product: {
        name: item.productName,
        brand: item.brandName,
      },
      contentSource: 'carousel tile',
      sourceName: props.heading,
      sourceCMSName: props.name,
    })
  )
}

onBeforeMount(() => {
  fetchRecommendations('browse', {
    category: props.category,
  })
})
</script>

<template>
  <section :class="sectionClasses" data-test-id="carousel_container">
    <div
      v-if="isThemed"
      class="WidgetProductCarousel_Image"
      data-test-id="carousel_image"
    >
      <SkyImage
        v-if="mobileImage.url && !isMediumUp"
        :src="
          transformImage(mobileImage.url, `${DEFAULT_TRANSFORMATIONS},w_560`)
        "
        :alt="mobileImageAltText || mobileImage.description"
      />
      <SkyImage
        v-else-if="desktopImage.url && isMediumUp"
        :src="
          transformImage(desktopImage.url, `${DEFAULT_TRANSFORMATIONS},w_736`)
        "
        :alt="desktopAltText || desktopImage.description"
      />
    </div>

    <div class="WidgetProductCarousel_Headings">
      <h2 v-if="heading" class="WidgetProductCarousel_Headings_Title">
        {{ heading }}
      </h2>
      <p
        v-if="subheading"
        class="WidgetProductCarousel_Headings_Subtitle"
        data-test-id="carousel_subtitle"
      >
        {{ subheading }}
      </p>
    </div>

    <SkyCarousel
      v-if="catalogItems.length"
      class="WidgetProductCarousel_Tiles"
      :navigation="showNav"
      :slides-to-show="numItemsToDisplay"
      :slides-to-scroll="Math.round(numItemsToDisplay - 1)"
    >
      <SkyCard
        v-for="(item, index) in catalogItems"
        :key="item.variantId"
        :class="tileClasses"
        :aria-label="
          numItemsToDisplay > 1 &&
          `slide ${index + 1} of ${catalogItems.length}`
        "
        aria-roledescription="slide"
        role="group"
        rounded
        :data-cnstrc-item-price="item.price"
        :data-cnstrc-item-id="item.variantId"
        :data-cnstrc-item-name="item.name"
        :data-cnstrc-item-variation-id="item.variantId"
        :data-cnstrc-item-shopify-variation-id="item.shopifyVariantId"
        :data-cnstrc-item-shopify-id="item.shopifyProductId"
      >
        <SkyProductTile
          :class="{
            'WidgetProductCarousel_ProductTile--vip':
              item.hasVipSpecialPricingDiscount,
          }"
          :brand-name="item.brandName"
          :image-alt="item.name"
          :image-placeholder="FALLBACK_PRODUCT_IMAGE"
          :image-src="
            transformImage(
              item.imageSrc,
              `${PRODUCT_TILE_TRANSFORMATIONS}${
                item.outOfStock ? ',e_grayscale' : ''
              }`
            )
          "
          :offer-price="item.price"
          :offer-text="hasPlacedOrder ? item.discountText : null"
          :price="hasPlacedOrder ? item.listPrice : null"
          :product-name="item.productName"
          :product-url="item.productUrl.toString()"
          :product-rating="item.reviewRating"
          :rating-count="item.reviewCount"
          :secondary-text="null"
          :variant-count="0"
          align-all-items
          @click="trackCatalogClick(item)"
        >
          <template
            v-if="item.badgeText || item.hasVipSpecialPricingDiscount"
            #topBadge
          >
            <SkyBadge
              v-if="item.badgeText"
              :inverted="item.badgeText === BACK_SOON_BADGE_TEXT"
            >
              {{ item.badgeText }}
            </SkyBadge>
            <SkyBadge
              v-if="item.hasVipSpecialPricingDiscount"
              class="WidgetProductCarousel_ProductTileBadge--vip"
            >
              VIP Discount
            </SkyBadge>
          </template>
          <template #bottomBadge>
            <SkyImage
              v-if="item.beyondPlasticImg"
              :src="item.beyondPlasticImg"
              :alt="item.beyondPlasticAlt"
              :width="isSmallUp ? 64 : 52"
              data-test-id="bottom-badge"
            />
          </template>
          <template v-if="hasPlacedOrder && item.variantId" #cta>
            <WaitlistButton
              v-if="item.outOfStock"
              :variant-id="item.variantId"
              data-test-id="waitlist"
            />
            <AddToCartButton
              v-else
              :variant-id="item.variantId"
              data-test-id="add-to-cart"
            >
              Add to Cart
            </AddToCartButton>
          </template>
          <template v-if="item.isSubscribable && item.savePrice" #infoBadge>
            <SkyBadge
              class="WidgetProductCarousel_Info_Badge"
              data-test-id="info-badge"
            >
              <SkyIcon v-if="!isMediumUp" name="frequency" size="20px" />
              <SkyPrice :price="item.savePrice" />
              <template v-if="isMediumUp"> Subscribe and save </template>
            </SkyBadge>
          </template>
          <template v-if="hasPlacedOrder && item.variantId" #iconButton>
            <AddToFavoritesIconButton
              :variant-id="item.variantId"
              data-test-id="add-favorite"
            />
          </template>
        </SkyProductTile>
      </SkyCard>
    </SkyCarousel>
    <div
      v-else
      class="WidgetProductCarousel_Tiles WidgetProductCarousel_Tiles-loading SkyCarousel"
      aria-hidden="true"
    >
      <ol class="SkyCarousel_Slides">
        <li
          v-for="item in Math.round(numItemsToDisplay)"
          :key="`placeholder-${item}`"
          class="SkyCarousel_Slide"
        >
          <SkyCard :class="tileClasses" rounded>
            <SkyImage :src="FALLBACK_PRODUCT_IMAGE" />
          </SkyCard>
        </li>
      </ol>
    </div>
    <div
      v-if="ctaText && ctaDestinationUrl"
      class="WidgetProductCarousel_CTA"
      data-test-id="carousel_CTA"
    >
      <SkyLink :to="ctaDestinationUrl" inline>
        {{ ctaText }}
      </SkyLink>
    </div>
  </section>
</template>

<style lang="scss">
.WidgetProductCarousel {
  display: grid;
  row-gap: var(--spacing-4x);

  @include for-medium-up {
    row-gap: var(--spacing-8x);
  }

  &_Headings {
    &_Title {
      margin-left: var(--spacing-4x);
      text-wrap: wrap;

      @include type-brand-heading('2');

      @include for-medium-up {
        margin-left: 0;
        @include type-brand-display('2');
      }
    }

    &_Subtitle {
      @include type-body('m');
      margin-top: var(--spacing-2x);

      line-height: var(--line-height-tight);
      @include for-medium-up {
        @include type-body('l');
      }
    }
  }

  &_CTA {
    text-align: center;
    font-size: var(--font-size-75);

    @include for-medium-up {
      font-size: var(--font-size-100);
    }
  }

  &_Image {
    margin: 0 var(--spacing-4x);
  }

  &_Tiles {
    &-loading {
      --slide-width: 40%;
      @include for-medium-up {
        --slide-width: 20%;
      }
      .SkyCarousel_Slides {
        overflow: hidden;
      }
    }
  }

  .SkyCarousel_Slides {
    // Fixes odd Chrome only reflow issue where bottom 1px is sometimes lost.
    padding-bottom: 1px;
  }

  &_Tile {
    --font-size-200: var(--font-size-100);
    margin: 0 calc(var(--spacing-2x) * -1) 0 var(--spacing-4x);
    padding: var(--spacing-3x);
    overflow: hidden;
    height: 100%;

    @include for-medium-up {
      margin: 0 var(--spacing-1x);
    }

    .SkyProductTile_ImageContainer {
      margin-bottom: 0;
    }

    .SkyProductTile_Image {
      min-height: auto;
    }

    .SkyProductTile_Link {
      --font-size-100: var(--font-size-75);
    }
  }

  &--themed {
    @include for-medium-up {
      grid-template-columns: 0.7fr 1fr;
      grid-template-rows: auto 1fr auto;
      column-gap: var(--spacing-4x);

      .WidgetProductCarousel {
        &_Image {
          grid-row: 1;
          grid-column: 1;
          margin: 0;
        }

        &_Headings {
          margin-left: var(--spacing-6x);
          grid-row: 1;
          align-self: center;

          &_Subtitle {
            margin-top: var(--spacing-2x);
          }
        }

        &_Tiles {
          grid-row: 2;
          grid-column: 1 / span 2;
        }

        &_CTA {
          grid-column: 1 / span 2;
        }
      }
    }

    @include for-large-up {
      .WidgetProductCarousel {
        &_Image {
          grid-row: 1 / span 3;
          grid-column: 1;
        }

        &_Tiles {
          grid-column: 2;
        }

        &_CTA {
          grid-column: 2;
        }
      }
    }
  }
  &_ProductTileBadge {
    &--vip {
      --surface-color-dark: var(--surface-color-vip);
    }
  }

  &_ProductTile {
    &--vip {
      .SkyPrice {
        --text-color-savings: var(--text-color-vip);
      }
    }
  }

  &_Info_Badge {
    --surface-color-dark: var(--surface-color-sunshine);
    --text-color-primary-ondark: var(--text-color-primary);
    display: flex;
    align-items: center;
    gap: var(--spacing-halfx);

    @include for-small-down {
      padding-left: var(--spacing-halfx);
    }

    .SkyPrice_Price {
      font-size: var(--font-size-50);
    }
  }
}
</style>
